<template>
	<div class="accountSafe">
		<template v-if="!showApplyLogout">
			<ul class="safeul">
				<li class="safeli">
					<div class="top">
						<div class="imgwrapper">
							<svg-icon icon-class="center-safe-pwd"></svg-icon>
						</div>
						<div class="txt">
							<div class="title">
								{{ $t('WDNPC_WEB_LOGIN_PASSWORD', { text: '登录密码' }) }}
							</div>
							<div class="desc">
								{{
									$t('WDNPC_WEB_WE_RECOMMEND_THAT_YOU_CHANGE_YOUR_PASSWORD', {
										text: '建议您定期更换密码，设置安全性高的密码可以使帐号更安全',
									})
								}}
							</div>
						</div>
					</div>
					<div class="bottom">
						<el-button class="opebtn" type="primary" @click="modifyPswoDialog = true">
							{{ $t('WDNPC_WEB_CHANGE_PASSWORD', { text: '修改密码' }) }}
						</el-button>
					</div>
				</li>
				<li class="safeli">
					<div class="top">
						<div class="imgwrapper">
							<svg-icon icon-class="center-safe-phone"></svg-icon>
						</div>
						<div class="txt">
							<div class="title">
								{{
									subUserInfo.phone
										? $t('WDNPC_WEB_SECURE_MOBILE_PHONE', { text: '安全手机' }) + ' ' + subUserInfo.phone
										: $t('WDNPC_WEB_CURRENTLY_THE_SECURITY_PHONE_HAS_NOT_BEEN', { text: '当前还未绑定安全手机' })
								}}
							</div>
							<div class="desc">
								{{ $t('WDNPC_WEB_SECURE_MOBILE_PHONES_CAN_BE_USED_TO', { text: '安全手机可以用于登录帐号，重置密码或其他安全验证' }) }}
							</div>
						</div>
					</div>
					<div class="bottom">
						<el-button class="opebtn" type="primary" @click="toPhone">
							{{ subUserInfo.phone ? $t('WDNPC_WEB_REPLACE', { text: '更换' }) : $t('WDNPC_WEB_BIND', { text: '去绑定' }) }}
						</el-button>
					</div>
				</li>
				<li class="safeli">
					<div class="top">
						<div class="imgwrapper">
							<svg-icon icon-class="center-safe-email"></svg-icon>
						</div>
						<div class="txt">
							<div class="title">
								{{
									subUserInfo.mail
										? subUserInfo.mail
										: $t('WDNPC_WEB_THE_EMAIL_ADDRESS_IS_NOT_CURRENTLY_BOUND', { text: '当前还未绑定邮箱地址' })
								}}
							</div>
							<div class="desc">
								{{ $t('WDNPC_WEB_YOU_CAN_BIND_A_MAILBOX_TO_RESET', { text: '绑定邮箱可以用于登录帐号，重置密码或其他安全验证' }) }}
							</div>
						</div>
					</div>
					<div class="bottom">
						<el-button class="opebtn" type="primary" @click="toemail">
							{{ subUserInfo.mail ? $t('WDNPC_WEB_REPLACE', { text: '更换' }) : $t('WDNPC_WEB_BIND', { text: '去绑定' }) }}
						</el-button>
					</div>
				</li>
				<li class="safeli">
					<div class="top">
						<div class="imgwrapper">
							<svg-icon icon-class="center-safe-account"></svg-icon>
						</div>
						<div class="txt">
							<div class="title">
								{{ $t('WDNPC_WEB_CANCEL_AN_ACCOUNT', { text: '注销账号' }) }}
							</div>
							<div class="desc">
								{{ $t('WDNPC_WEB_COMPLETELY_DISABLE_THIS_ACCOUNT_THAT_IS_NO', { text: '彻底关闭不再使用的该账号' }) }}
							</div>
						</div>
					</div>
					<div class="bottom">
						<el-button class="opebtn" type="primary" @click="showApplyLogout = true">
							{{ $t('WDNPC_WEB_APPLY_FOR_CANCELLATION', { text: '申请注销' }) }}
						</el-button>
					</div>
				</li>
			</ul>
		</template>
		<template v-else>
			<div class="applyNotice" v-if="!logoutSuccess">
				<div class="header">
					<img class="icon" src="../../../assets/images/common/error.png" alt="" />
					<p class="headertitle">{{ $t('WDNPC_WEB_WE_WILL_REVIEW_THE_FOLLOWING_INFORMATION', { text: '我们将对以下信息进行审核' }) }}</p>
				</div>
				<ul class="ul">
					<li>1{{ $t('WDNPC_WEB_THE_ACCOUNT_IS_IN_THE_VALID_STATE', { text: '、账号当前为有效状态' }) }}</li>
					<li>2{{ $t('WDNPC_WEB_NO_OUTSTANDING_ORDER_IN_ACCOUNT', { text: '、账号内无未完成状态订单' }) }}</li>
					<li>3{{ $t('WDNPC_WEB_ACCOUNT_WITHOUT_ANY_DISPUTE', { text: '、账户无任何纠纷' }) }}</li>
					<li>4{{ $t('WDNPC_WEB_ALL_COURSES_OR_LEARNING_TASKS_HAVE_BEEN', { text: '、已完成所有课程或学习任务' }) }}</li>
				</ul>
				<div class="footer">
					<el-checkbox v-model="isread"
						>{{
							$t('WDNPC_WEB_APPLYING_FOR_CANCELLATION_MEANS_THAT_YOU_VOLUNTARILY', { text: '申请注销即表示您自愿放弃账号内所有虚拟资产并同意' })
						}}
					</el-checkbox>
					<span @click="readDialog = true" style="color:#316FFF;cursor:pointer">
						{{ $t('WDNPC_WEB_ACCOUNT_CANCELLATION_INSTRUCTIONS', { text: '《账号注销须知》' }) }}</span
					>
					<div class="footertip">*{{ $t('WDNPC_WEB_AFTER_SUCCESSFULLY_LOGGING_OUT', {text: '注销成功后，您将无法再登录此账户，且以往的所有学习记录、订单记录都将无法恢复。' })}}</div>
				</div>
				<div class="confirmBtn">
					<el-button type="primary" @click="confirmApplyLogout" :class="{ grayBtn: !isread }">{{
						$t('WDNPC_WEB_APPLY_FOR_CANCELLATION', { text: '申请注销' })
					}}</el-button>
					<el-button class="back" @click="backApplyLogout">{{ $t('WDNPC_WEB_RETURN', { text: '返回' }) }}</el-button>
				</div>
			</div>
			<div v-else class="applysuccess">
				<div class="header">
					<img class="icon" src="../../../assets/images/common/success.png" alt="" />
				</div>
				<div class="successtip">
					<p>{{ $t('WDNPC_WEB_YOU_WILL_NO_LONGER_BE_ABLE_TO', { text: '您的账号注销申请已完成，即时起您将无法再登录此账户。' }) }}</p>
					<p>
						{{
							$t('WDNPC_WEB_THE_ACCOUNT_CAN_BE_REREGISTERED_BUT_ALL', {
								text: '该账户可重新进行注册，但以往的所有学习记录、订单记录都将无法恢复。',
							})
						}}
					</p>
				</div>
				<div class="confirmBtn">
					<el-button class="iknow" type="primary" @click="backHome">{{ $t('WDNPC_WEB_I_KNOW', { text: '我知道了' }) }}</el-button>
				</div>
			</div>
		</template>
		<!-- 修改密码 -->
		<el-dialog class="modifyPswoDialog modifyDialog" :visible.sync="modifyPswoDialog" :close-on-click-modal="false" width="400px" center>
			<div slot="title" class="title">
				{{ $t('WDNPC_WEB_CHANGE_LOGIN_PASSWORD', { text: '修改登录密码' }) }}
			</div>
			<el-input
				v-model="modifyPassword.password"
				:type="psdtype1"
				auto-complete="new-password"
				:placeholder="$t('WDNPC_WEB_PLEASE_ENTER_THE_ORIGINAL_LOGIN_PASSWORD', { text: '请输入原登录密码' })"
			>
				<template slot="append">
					<span @click="handleshowPsd(1)" class="pwd-icon">
						<svg-icon icon-class="pwd-yincang" v-if="psdtype1 == 'text'"></svg-icon>
						<svg-icon v-else icon-class="pwd-zhanshi"></svg-icon>
					</span>
				</template>
			</el-input>
			<el-input
				v-model="modifyPassword.passwordNew"
				@blur="comparePassword"
				:type="psdtype2"
				auto-complete="new-password"
				:placeholder="$t('WDNPC_WEB_SET_A_NEW_LOGIN_PASSWORD_TO_THE', { text: '设置8至20位新登录密码' })"
			>
				<template slot="append">
					<span @click="handleshowPsd(2)" class="pwd-icon">
						<svg-icon icon-class="pwd-yincang" v-if="psdtype2 == 'text'"></svg-icon>
						<svg-icon v-else icon-class="pwd-zhanshi"></svg-icon>
					</span>
				</template>
			</el-input>
			<el-input
				v-model="modifyPassword.repsw"
				@blur="comparePassword"
				:type="psdtype3"
				auto-complete="new-password"
				:placeholder="$t('WDNPC_WEB_PLEASE_ENTER_THE_LOGIN_PASSWORD_AGAIN', { text: '请再次输入登录密码' })"
			>
				<template slot="append">
					<span @click="handleshowPsd(3)" class="pwd-icon">
						<svg-icon icon-class="pwd-yincang" v-if="psdtype3 == 'text'"></svg-icon>
						<svg-icon v-else icon-class="pwd-zhanshi"></svg-icon>
					</span>
				</template>
			</el-input>
			<span slot="footer" class="dialog-footer">
				<el-button
					type="primary"
					:class="{ grayBtn: !modifyPassword.password || !modifyPassword.passwordNew || !modifyPassword.repsw }"
					@click="handleModifyPsd"
				>
					{{ $t('WDNPC_WEB_OK', { text: '确 定' }) }}
				</el-button>
			</span>
		</el-dialog>
		<!-- 更换手机 -->
		<el-dialog
			class="modifyPhoneDialog modifyDialog"
			:visible.sync="modifyPhoneDialog"
			:close-on-click-modal="false"
			width="400px"
			center
			@closed="handleClose"
		>
			<div class="progress">
				<div class="step1" :class="{ highlight: modifyPhoneStep >= 1 }">
					1
					<div class="stepTxt">{{ $t('WDNPC_WEB_VERIFY_IDENTITY', { text: '验证身份' }) }}</div>
				</div>
				<div class="step2" :class="{ highlight: modifyPhoneStep >= 2 }">
					2
					<div class="stepTxt">{{ $t('WDNPC_WEB_MODIFY_MOBILE_PHONE_NUMBER', { text: '修改手机号' }) }}</div>
				</div>
				<div class="step3" :class="{ highlight: modifyPhoneStep >= 3 }">
					3
					<div class="stepTxt">{{ $t('WDNPC_WEB_COMPLETE_REPLACEMENT', { text: '完成更换' }) }}</div>
				</div>
			</div>
			<template v-if="modifyPhoneStep == 1">
				<div class="desc">
					<p class="desc1">{{ $t('WDNPC_WEB_BOUND_PHONE', { text: '已绑定的手机：' }) }}{{ subUserInfo.phone }}</p>
					<!-- <p class="desc2">若该手机号已无法使用请联系客服</p> -->
                    <template v-if="!is_i18n">
                        <p class="desc2">
                            {{ $t('WDNPC_WEB_IF_THE_MOBILE_PHONE_NUMBER_IS_NO', { text: '若该手机号已无法使用，请点击' })
                            }}<span class="face" @click="handleFaceRecognition">{{ $t('WDNPC_WEB_FACE_RECOGNITION', { text: '人脸识别' }) }}</span
                            >{{ $t('WDNPC_WEB_VERIFY_IDENTITY', { text: '验证身份' }) }}
                        </p>
                        <p class="desc3">
                            {{ $t('WDNPC_WEB_ONLY_AFTER_REALNAME_AUTHENTICATION_CAN_FACE_RECOGNITION', { text: '（注：实名认证后才能人脸识别验证身份）' }) }}
                        </p>
                    </template>
				</div>
				<div id="QRcode" v-if="isShowQRcode" style="width:180px;height:180px;margin:0 auto;margin-bottom:24px"></div>

				<el-input
					class="noRightRadius"
					v-else
					v-model="phoneCode"
					:placeholder="$t('WDNPC_WEB_PLEASE_ENTER_A_VERIFICATION_CODE', { text: '请输入验证码' })"
				>
					<template slot="append">
						<span @click="postAccountGetAuthCode2('oldphone')" :style="{ color: 0 >= Countdown2 ? '#316fff' : '#BFC8D9', cursor: 'pointer' }">
							{{ 0 >= Countdown2 ? $t('WDNPC_WEB_OBTAIN_VERIFICATION_CODE', { text: '获取验证码' }) : Countdown2 + 's' }}
						</span>
					</template>
				</el-input>
			</template>
			<template v-else-if="modifyPhoneStep == 2">
				<div class="form">
					<el-input
						v-model="modifyAccount.account"
						:placeholder="$t('WDNPC_WEB_PLEASE_ENTER_A_NEW_MOBILE_PHONE_NUMBER', { text: '请输入新的手机号' })"
					></el-input>
					<el-input
						class="noRightRadius"
						v-model="modifyAccount.authCode"
						:placeholder="$t('WDNPC_WEB_PLEASE_ENTER_A_VERIFICATION_CODE', { text: '请输入验证码' })"
					>
						<template slot="append">
							<span @click="postAccountGetAuthCode2('newphone')" :style="{ color: 0 >= Countdown2 ? '#316fff' : '#BFC8D9', cursor: 'pointer' }">
								{{ 0 >= Countdown2 ? $t('WDNPC_WEB_OBTAIN_VERIFICATION_CODE', { text: '获取验证码' }) : Countdown2 + 's' }}
							</span>
						</template>
					</el-input>
				</div>
			</template>
			<template v-else>
				<div class="desc successdesc">
					<div class="desc1">{{ $t('WDNPC_WEB_THE_MOBILE_PHONE_NUMBER_HAS_BEEN_CHANGED', { text: '手机号码更换成功' }) }}</div>
					<div class="desc1">{{ $t('WDNPC_WEB_PLEASE_LOG_IN_WITH_A_NEW_MOBILE', { text: '请在下次使用新的手机号码进行登录' }) }}</div>
				</div>
			</template>
			<span slot="footer" v-if="!isShowQRcode || modifyPhoneStep !== 1" class="dialog-footer">
				<el-button type="primary" :class="{ grayBtn: modifyPhoneBtnColor }" @click="handleModifyPhone(modifyPhoneStep)"
					>{{ $t('WDNPC_WEB_OK', { text: '确 定' }) }}
				</el-button>
			</span>
		</el-dialog>
		<!-- 更换邮箱 -->
		<el-dialog class="modifyEmailDialog modifyDialog" :visible.sync="modifyEmailDialog" :close-on-click-modal="false" width="400px" center>
			<div class="progress">
				<div class="step1" :class="{ highlight: modifyEmailStep >= 1 }">
					1
					<div class="stepTxt">{{ $t('WDNPC_WEB_VERIFY_IDENTITY', { text: '验证身份' }) }}</div>
				</div>
				<div class="step2" :class="{ highlight: modifyEmailStep >= 2 }">
					2
					<div class="stepTxt">{{ $t('WDNPC_WEB_MODIFY_EMAIL_ACCOUNT', { text: '修改邮箱账号' }) }}</div>
				</div>
				<div class="step3" :class="{ highlight: modifyEmailStep >= 3 }">
					3
					<div class="stepTxt">{{ $t('WDNPC_WEB_COMPLETE_REPLACEMENT', { text: '完成更换' }) }}</div>
				</div>
			</div>
			<template v-if="modifyEmailStep == 1">
				<div class="desc">
					<p class="desc1">{{ $t('WDNPC_WEB_BOUND_MAILBOX', { text: '已绑定的邮箱：' }) }}{{ subUserInfo.mail }}</p>
					<p class="desc1">{{ $t('WDNPC_WEB_IF_THE_EMAIL_ADDRESS_IS_UNAVAILABLE_PLEASE', { text: '若该邮箱已无法使用请联系客服' }) }}</p>
				</div>
				<el-input class="noRightRadius" v-model="mailCode" :placeholder="$t('WDNPC_WEB_PLEASE_ENTER_A_VERIFICATION_CODE', { text: '请输入验证码' })">
					<template slot="append">
						<span @click="postAccountGetAuthCode3('oldmail')" :style="{ color: 0 >= Countdown3 ? '#316fff' : '#BFC8D9', cursor: 'pointer' }">
							{{ 0 >= Countdown3 ? $t('WDNPC_WEB_OBTAIN_VERIFICATION_CODE', { text: '获取验证码' }) : Countdown3 + 's' }}
						</span>
					</template>
				</el-input>
			</template>
			<template v-else-if="modifyEmailStep == 2">
				<div class="form">
					<el-input
						v-model="modifyAccount.account"
						:placeholder="$t('WDNPC_WEB_PLEASE_ENTER_A_NEW_MAILBOX_NUMBER', { text: '请输入新的邮箱号' })"
					></el-input>
					<el-input
						class="noRightRadius"
						v-model="modifyAccount.authCode"
						:placeholder="$t('WDNPC_WEB_PLEASE_ENTER_A_VERIFICATION_CODE', { text: '请输入验证码' })"
					>
						<template slot="append">
							<span @click="postAccountGetAuthCode3('nowmail')" :style="{ color: 0 >= Countdown3 ? '#316fff' : '#BFC8D9', cursor: 'pointer' }">
								{{ 0 >= Countdown3 ? $t('WDNPC_WEB_OBTAIN_VERIFICATION_CODE', { text: '获取验证码' }) : Countdown3 + 's' }}
							</span>
						</template>
					</el-input>
				</div>
			</template>
			<template v-else>
				<div class="desc successdesc">
					<div class="desc1">{{ $t('WDNPC_WEB_EMAIL_CHANGED_SUCCESSFULLY', { text: '邮箱更换成功' }) }}</div>
					<div class="desc1">{{ $t('WDNPC_WEB_PLEASE_LOG_IN_WITH_A_NEW_MAILBOX', { text: '请在下次使用新的邮箱进行登录' }) }}</div>
				</div>
			</template>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" :class="{ grayBtn: modifyEmailBtnColor }" @click="handleModifyEmail(modifyEmailStep)">
					{{ $t('WDNPC_WEB_OK', { text: '确 定' }) }}
				</el-button>
			</span>
		</el-dialog>
		<!-- 绑定邮箱 -->
		<el-dialog class="bindEmailDialog modifyDialog" :visible.sync="bindEmailDialog" :close-on-click-modal="false" width="400px" center>
			<div slot="title" class="title">
				{{ $t('WDNPC_WEB_BIND_EMAIL_ADDRESS', { text: '绑定邮箱地址' }) }}
			</div>
			<el-input v-model="bindAccount.account" :placeholder="$t('WDNPC_WEB_ENTER_AN_EMAIL_ADDRESS', { text: '请输入邮箱地址' })"></el-input>
			<el-input
				class="noRightRadius"
				v-model="bindAccount.authCode"
				:placeholder="$t('WDNPC_WEB_PLEASE_ENTER_A_VERIFICATION_CODE', { text: '请输入验证码' })"
			>
				<template slot="append">
					<span :style="{ color: 0 >= Countdown1 ? '#316FFF' : '#666', cursor: 'pointer' }" @click="postAccountGetAuthCode1">{{
						0 >= Countdown1 ? $t('WDNPC_WEB_OBTAIN_VERIFICATION_CODE', { text: '获取验证码' }) : Countdown1 + 's'
					}}</span>
				</template>
			</el-input>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" :class="{ grayBtn: !bindAccount.authCode }" @click="handleBindMail">
					{{ $t('WDNPC_WEB_OK', { text: '确 定' }) }}
				</el-button>
			</span>
		</el-dialog>
		<!-- 绑定手机号 -->
		<el-dialog class="bindPhoneDialog modifyDialog" :visible.sync="bindPhoneDialog" :close-on-click-modal="false" width="400px" center>
			<div slot="title" class="title">
				{{ $t('WDNPC_WEB_BIND_SECURITY_PHONE', { text: '绑定安全手机' }) }}
			</div>
			<el-input v-model="bindAccount.account" :placeholder="$t('WDNPC_WEB_PLEASE_ENTER_YOUR_MOBILE_PHONE_NUMBER', { text: '请输入手机号' })"></el-input>
			<el-input
				class="noRightRadius"
				v-model="bindAccount.authCode"
				:placeholder="$t('WDNPC_WEB_PLEASE_ENTER_A_VERIFICATION_CODE', { text: '请输入验证码' })"
			>
				<template slot="append">
					<span :style="{ color: 0 >= Countdown4 ? '#316FFF' : '#666', cursor: 'pointer' }" @click="postAccountGetAuthCode4">{{
						0 >= Countdown4 ? $t('WDNPC_WEB_OBTAIN_VERIFICATION_CODE', { text: '获取验证码' }) : Countdown4 + 's'
					}}</span>
				</template>
			</el-input>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" :class="{ grayBtn: !bindAccount.authCode }" @click="handleBindPhone">
					{{ $t('WDNPC_WEB_OK', { text: '确 定' }) }}
				</el-button>
			</span>
		</el-dialog>
		<!-- 账号注销 -->
		<el-dialog class="readDialog" :visible.sync="readDialog" width="800px" center>
			<div slot="title" class="title">
				{{ $t('WDNPC_WEB_ACCOUNT_CANCELLATION_INSTRUCTIONS', { text: '账号注销须知' }) }}
			</div>
			<div class="cancelNotice" style="overflow:auto">
				<p class="toUser">{{ $t('WDNPC_WEB_DEAR_USER', { text: '亲爱的用户：' }) }}</p>
				<p style="text-indent: 32px;">
					{{
						$t('WDNPC_WEB_BEFORE_YOU_CLICK_AGREE_IN_THE_PROCESS', {
							text: '您在申请注销流程中点击同意前，应当认真阅读《账户注销须知》（以下称“《注销须知》',
						})
					}}”）。
				</p>
				<p>
					{{
						$t('WDNPC_WEB_PLEASE_CAREFULLY_READ_AND_FULLY_UNDERSTAND_THE', {
							text: '请您务必审慎阅读、充分理解《注销须知》中相关条款内容，其中包括：',
						})
					}}
				</p>
				<p>1{{ $t('WDNPC_WEB_TERMS_OF_EXEMPTION_OR_LIMITATION_WITH_YOU', { text: '、与您约定免除或限制责任的条款；' }) }}</p>
				<p>2{{ $t('WDNPC_WEB_OTHER_IMPORTANT_CLAUSES_MARKED_IN_BOLD', { text: '、其他以粗体标识的重要条款。' }) }}</p>
				<p>
					【{{
						$t('WDNPC_WEB_SPECIAL_TIPS_AFTER_YOU_FILL_IN_THE', {
							text:
								'特别提示】当您按照注销页面提示填写信息、阅读并同意本《注销须知》及相关条款与条件且完成全部注销程序后，即表示您已充分阅读、理解并接受本《注销须知》的全部内容。阅读本《注销须知》的过程中，如果您不同意相关任何条款和条件约定，请您立即停止账户注销程序。',
						})
					}}
				</p>
				<p>
					{{
						$t('WDNPC_WEB_WE_HEREBY_KINDLY_REMIND_YOU_THAT_THE', {
							text:
								'我们在此善意地提醒您，您注销账户的行为会给您的售后维权带来诸多不便，且注销账户后，您的个人信息我们只会在商城的前台系统中去除，使其保持不可被检索、访问的状态，或对其进行匿名化处理。您知晓并理解，根据相关法律规定，相关交易记录须在后台保存5年甚至更长的时间。',
						})
					}}
				</p>
				<h3>1{{ $t('WDNPC_WEB_IF_YOU_STILL_INSIST_ON_CANCELING_YOUR', { text: '、如果您仍执意注销账户，您的账户需同时满足以下条件：' }) }}</h3>
				<p>
					（1{{
						$t('WDNPC_WEB_VOLUNTARILY_WAIVES_THE_ASSETS_AND_VIRTUAL_INTERESTS', {
							text: '）自愿放弃账户在商城系统中的资产和虚拟权益（包括但不限于账户余额、优惠券等），并确保账户中无无欠款；',
						})
					}}
				</p>
				<p>（1{{ $t('WDNPC_WEB_NO_OUTSTANDING_ORDERS_AND_SERVICES_IN_THE', { text: '）账户内无未完成的订单和服务；' }) }}</p>
				<p>（1{{ $t('WDNPC_WEB_THERE_ARE_NO_DISPUTES_IN_THE_ACCOUNT', { text: '）账户无任何纠纷，包括投诉举报或被投诉举报；' }) }}</p>
				<h3>
					2{{
						$t('WDNPC_WEB_ONCE_THE_ACCOUNT_IS_CANCELED_IT_WILL', {
							text:
								'、账户一旦被注销将不可恢复，请您在操作之前自行备份账户相关的所有信息和数据。请您保存好订单商品和服务的交易凭证、票据等资料，否则您有可能须支付额外的账户和订单查询费用，或无法享受售后服务。',
						})
					}}
				</h3>
				<h3>
					3{{
						$t('WDNPC_WEB_DURING_THE_CANCELLATION_PERIOD_IF_YOUR_ACCOUNT', {
							text:
								'、在账户注销期间，如果您的账户涉及争议纠纷，包括但不限于投诉、举报、诉讼、仲裁、国家有权机关调查等，有权自行终止本账户的注销而无需另行得到您的同意。',
						})
					}}
				</h3>
				<h3>
					4{{
						$t('WDNPC_WEB_YOU_WILL_NO_LONGER_BE_ABLE_TO_QYL', {
							text: '、注销账户，您将无法再使用本账户，也将无法找回您账户中及与账户相关的任何内容或信息，包括但不限于：',
						})
					}}
				</h3>
				<p>（1{{ $t('WDNPC_WEB_YOU_WILL_NOT_BE_ABLE_TO_LOG', { text: '）您将无法登录、使用本账户；' }) }}</p>
				<p>
					（1{{
						$t('WDNPC_WEB_PERSONAL_DATA_AND_HISTORICAL_INFORMATION_OF_THIS', {
							text: '）本账户的个人资料和历史信息（包括但不限于用户名、头像、购物记录、关注信息等）都将无法找回；',
						})
					}}
				</p>
				<p>
					（1{{
						$t('WDNPC_WEB_ALL_RECORDS_OF_RELATED_OR_THIRDPARTY_SERVICES', {
							text:
								'）您通过账户使用、授权登录或绑定账户后使用的相关或第三方的其他服务的所有记录将无法找回。您将无法再登录、使用前述服务，您曾获得的余额、优惠券、积分、资格、订单等视为您自行放弃，将无法继续使用。您理解并同意，无法协助您重新恢复前述服务。请您在提交注销申请前，务必先了解您须解绑的其他相关账户信息，具体可与我们的客服联系。',
						})
					}}
				</p>
				<h3>
					5{{
						$t('WDNPC_WEB_CANCELLATION_OF_THIS_ACCOUNT_DOES_NOT_MEAN', {
							text: '、注销本账户并不代表本账户注销前的账户行为和相关责任得到豁免或减轻。',
						})
					}}
				</h3>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button class="iKnow" type="primary" @click="readDialog = false">{{ $t('WDNPC_WEB_GOT_IT', { text: '知道了' }) }}</el-button>
			</span>
		</el-dialog>
		<!-- 人脸识别 -->
		<facedialog
			v-if="verification"
			@closeFacedialog="handleCloseFacedialog"
			:confirmdialog="confirmdialog"
			:type="4"
			:address="{}"
			:failAddress="{}"
		></facedialog>
	</div>
</template>

<script>
import MD5 from 'js-md5'
import QRCode from 'qrcodejs2'
import { isPhone, isEmail, passReg } from '@/utils/validator'
export default {
	name: 'accountSafe',
	data() {
		return {
			showApplyLogout: false,
			modifyPswoDialog: false,
			modifyPhoneDialog: false,
			modifyEmailDialog: false,
			bindPhoneDialog: false,
			bindEmailDialog: false,
			readDialog: false,
			isread: false,
			logoutSuccess: false,
			confirmdialog: {
				show: false,
			},
			modifyPassword: {
				password: '',
				passwordNew: '',
				repsw: '',
			},
			modifyAccount: {
				account: '',
				authCode: '',
			},
			bindAccount: {
				account: '',
				authCode: '',
			},
			email: '',
			showPsd: false,
			psdtype1: 'password',
			psdtype2: 'password',
			psdtype3: 'password',
			modifyPhoneStep: 1,
			modifyEmailStep: 1,
			phoneCode: '', //验证身份
			mailCode: '', //验证身份
			Countdown1: 0,
			Countdown2: 0,
			Countdown3: 0,
			Countdown4: 0,
			isRealName: false, //是否实名认证
			isShowQRcode: false, //显示实名认证二维码
			verification: false, //人脸识别
			result: false,
			mobile_prefix: '86',
            internationalKey: 'zh-CN',
            is_i18n: false,
		}
	},
	watch: {
		// 是否已进行人脸验证
		result(val) {
			if (val == true) {
				this.modifyPhoneStep = 2
			}
		},
	},
	computed: {
		modifyPhoneBtnColor() {
			if (this.modifyPhoneStep == 1 && !this.phoneCode) {
				return true
			} else if (this.modifyPhoneStep == 2 && !this.modifyAccount.authCode) {
				return true
			} else {
				return false
			}
		},
		modifyEmailBtnColor() {
			if (this.modifyEmailStep == 1 && !this.mailCode) {
				return true
			} else if (this.modifyEmailStep == 2 && !this.modifyAccount.authCode) {
				return true
			} else {
				return false
			}
		},
		subUserInfo() {
			return this.$store.state.subUserInfo
		},
	},
	created() {
        this.internationalKey = localStorage.getItem('current_language') || 'zh-CN'
        this.is_i18n = localStorage.getItem('is_i18n') === 'true'
		this.mobile_prefix = localStorage.getItem('mobile_prefix')
	},
	mounted() {},
	methods: {
		handleCloseFacedialog() {
			this.verification = false
		},
		async handleFaceRecognition() {
			await this.postIsRealNameAuthentication()
			if (this.isRealName) {
				// 人脸认证
				this.verification = true
			} else {
				// 实名认证
				if (this.QRCode) return
				this.isShowQRcode = true
				this.$nextTick(() => {
					this.qrcode()
				})
				// 开启轮询，判断用户是否实名认证
				this.startPoll()
			}
		},
		startPoll() {
			this.pollId = setInterval(() => {
				this.postIsRealNameAuthentication('poll')
			}, 3000)
		},
		postIsRealNameAuthentication(type) {
			return this.$api.monitor.postIsRealNameAuthentication({}).then((res) => {
				const m = `${localStorage.getItem('token')}true`
				this.isRealName = res.data === MD5(m)
				localStorage.setItem('isRealName', this.isRealName)
				// 开启轮询后，用户通过实名认证后可修改手机号
				if (type && this.isRealName) {
					this.modifyPhoneStep = 2
					clearInterval(this.pollId)
				}
			})
		},
		qrcode() {
			let domainArr = localStorage.getItem('domain')
			let domainUrl = domainArr && JSON.parse(domainArr).find((item) => item.frontend_template_id == 4).domain
			this.QRCode = new QRCode('QRcode', {
				width: 180,
				height: 180, // 高度
				text: `${window.location.protocol}//${domainUrl}/login?phone=${this.subUserInfo.phone}`, // 二维码内容
				display: 'none',
			})
		},
		// 关闭换绑手机号弹框
		handleClose() {
			this.QRCode = false
			this.isShowQRcode = false
			clearInterval(this.pollId)
		},
		// 修改密码
		handleModifyPsd() {
			if (!this.modifyPassword.password || !this.modifyPassword.passwordNew || !this.modifyPassword.repsw) return
			if (!this.comparePassword()) return
			if (!passReg.test(this.modifyPassword.passwordNew)) {
				this.$message.warning(this.$t('WDNPC_WEB_PLEASE_SET_THE_PASSWORD_OF_THE_COMBINATION', { text: '请设置8-20位，数字、大小写字母、字符组合密码' }))
				return
			}
			let data = {
				newPassword: this.modifyPassword.passwordNew,
				oldPassword: MD5(this.modifyPassword.password),
			}
			this.$api.account_new.changePasswordByOld(data).then((res) => {
				if (res.data) {
					this.modifyPswoDialog = false
					this.$message.success(this.$t('WDNPC_WEB_PASSWORD_MODIFIED_SUCCESSFULLY', { text: '密码修改成功' }))
					//重置表单
					this.modifyPassword = {
						password: '',
						passwordNew: '',
						repsw: '',
					}
				}
			})
		},
		handleshowPsd(num) {
			let type = this['psdtype' + num]
			if (type === 'password') {
				this['psdtype' + num] = 'text'
			} else {
				this['psdtype' + num] = 'password'
			}
		},
		comparePassword() {
			let result = true
			if (this.modifyPassword.passwordNew && this.modifyPassword.repsw) {
				if (this.modifyPassword.passwordNew !== this.modifyPassword.repsw) {
					this.$message.warning(this.$t('WDNPC_WEB_INCONSISTENT_PASSWORD_INPUT', { text: '两次密码输入不一致' }))
					result = false
				}
			}
			return result
		},
		// 绑定手机号
		handleBindPhone() {
			let data = {
				areaCode: this.mobile_prefix,
				phone: this.bindAccount.account,
				authCode: this.bindAccount.authCode,
			}
			this.$api.account_new.bindPhone(data).then((res) => {
				if (res.success) {
					this.bindPhoneDialog = false
					this.getUserInfo()
					//重置表单
					this.bindAccount = {
						account: '',
						authCode: '',
					}
					this.Countdown1 = 0
					this.Countdown4 = 0
				}
			})
		},
		// 绑定邮箱
		handleBindMail() {
			let data = {
				mail: this.bindAccount.account,
				authCode: this.bindAccount.authCode,
			}
			this.$api.account_new.bindMail(data).then((res) => {
				if (res.success) {
					this.bindEmailDialog = false
					this.getUserInfo()
					//重置表单
					this.bindAccount = {
						account: '',
						authCode: '',
					}
					this.Countdown1 = 0
					this.Countdown4 = 0
				}
			})
		},
		toPhone() {
			if (!isPhone.test(this.bindAccount.account)) {
				this.bindAccount.account = ''
				this.bindAccount.authCode = ''
			}
			if (!isPhone.test(this.modifyAccount.account)) {
				this.modifyAccount.account = ''
				this.modifyAccount.authCode = ''
			}
			if (this.subUserInfo.phone) {
				this.modifyPhoneDialog = true
			} else {
				this.bindPhoneDialog = true
			}
		},
		toemail() {
			if (!isEmail.test(this.bindAccount.account)) {
				this.bindAccount.account = ''
				this.bindAccount.authCode = ''
			}
			if (!isEmail.test(this.modifyAccount.account)) {
				this.modifyAccount.account = ''
				this.modifyAccount.authCode = ''
			}
			if (this.subUserInfo.mail) {
				this.modifyEmailDialog = true
			} else {
				this.bindEmailDialog = true
			}
		},
		// 更换手机
		handleModifyPhone(step) {
			if (step == 1) {
				this.checkAuthCode('phone')
				this.Countdown2 = 0
				this.modifyAccount.account = ''
				this.modifyAccount.authCode = ''
			} else if (step == 2) {
				this.unBindUserAccount('phone')
			} else {
				this.modifyPhoneDialog = false
				this.modifyPhoneStep = 1
				//重置表单
				this.modifyAccount.account = ''
				this.modifyAccount.authCode = ''
				this.Countdown2 = 0
			}
		},
		// 更换邮箱
		handleModifyEmail(step) {
			if (step == 1) {
				this.checkAuthCode('mail')
				this.Countdown3 = 0
				this.modifyAccount.account = ''
				this.modifyAccount.authCode = ''
			} else if (step == 2) {
				this.unBindUserAccount('mail')
			} else {
				this.modifyEmailDialog = false
				this.modifyEmailStep = 1
				//重置表单
				this.Countdown3 = 0
				this.modifyAccount.account = ''
				this.modifyAccount.authCode = ''
			}
		},
		// 验证身份
		checkAuthCode(type) {
			let result = false
			let authCode, step
			if (type === 'phone') {
				authCode = this.phoneCode
				step = 'modifyPhoneStep'
				this.$api.account_new.checkPhoneAuthCodeBySelf({ authCode }).then((res) => {
					result = res.data
					if (result) {
						this[step] = 2
					}
				})
			} else {
				authCode = this.mailCode
				step = 'modifyEmailStep'
				this.$api.account_new.checkMailAuthCodeBySelf({ authCode }).then((res) => {
					result = res.data
					if (result) {
						this[step] = 2
					}
				})
			}
		},
		// 更换手机号、邮箱
		unBindUserAccount(type) {
			if (type === 'phone') {
				this.changePhone()
			} else {
				this.changeMail()
			}
		},
		changePhone() {
			let data = {
				areaCode: this.mobile_prefix,
				newPhone: this.modifyAccount.account,
				newPhoneAuthCode: this.modifyAccount.authCode,
				oldPhoneAuthCode: this.phoneCode,
			}

			if (!data.newPhone) {
				this.$message.error(this.$t('WDNPC_WEB_PLEASE_ENTER_A_NEW_MOBILE_PHONE_NUMBER', { text: '请输入新的手机号' }))
				return
			}

			this.$api.account_new.changePhone(data).then((res) => {
				if (res.data) {
					this.modifyPhoneStep = 3
					this.getUserInfo()
				}
			})
		},
		changeMail() {
			let data = {
				newMail: this.modifyAccount.account,
				newMailAuthCode: this.modifyAccount.authCode,
				oldMailAuthCode: this.mailCode,
			}

			if (!data.newMail) {
				this.$message.error(this.$t('WDNPC_WEB_PLEASE_ENTER_A_NEW_MAILBOX_NUMBER', { text: '请输入新的邮箱号' }))
				return
			}

			this.$api.account_new.changeMail(data).then((res) => {
				if (res.data) {
					this.modifyEmailStep = 3
					this.getUserInfo()
				}
			})
		},
		// 获取用户信息
		getUserInfo() {
			this.$api.user_new.getUserInfo().then((res) => {
				if (res.success) {
					this.$store.commit('saveSubUserInfo', res.data)
					localStorage.setItem('subUserInfo', JSON.stringify(res.data))
				}
			})
		},
		// 申请注销
		confirmApplyLogout() {
			if (!this.isread) return
			this.$api.account_new.destory().then((res) => {
				if (res.data) {
					this.logoutSuccess = true
					localStorage.clear()
				}
			})
		},
		backApplyLogout() {
			this.showApplyLogout = false
			this.isread = false
		},
		// 绑定邮箱 发送验证码
		async postAccountGetAuthCode1() {
			this.bindAccount.authCode = null
			if (isEmail.test(this.bindAccount.account)) {
				if (this.Countdown1 > 0) return
				this.Countdown1 = 60
				this.setCountdown1()
				this.$sensors.track('UM_Click_Verification_Code_Button')
				this.$api.account_new.getMailAuthCode4ChangeMail({ mail: this.bindAccount.account })
			} else {
				this.$message.error(this.$t('WDNPC_WEB_ENTER_THE_CORRECT_EMAIL_ACCOUNT', { text: '请输入正确的邮箱账号' }))
			}
		},
		// 更换手机 发送验证码
		async postAccountGetAuthCode2(type) {
			if (type === 'oldphone') {
				if (this.Countdown2 > 0) return
				this.Countdown2 = 60
				this.setCountdown2()
				this.$sensors.track('UM_Click_Verification_Code_Button')
				this.$api.account_new.getPhoneAuthCodeBySelf()
			} else {
				if (isPhone.test(this.modifyAccount.account)) {
					if (this.Countdown2 > 0) return
					this.Countdown2 = 60
					this.setCountdown2()
					this.$sensors.track('UM_Click_Verification_Code_Button')
					this.$api.account_new.getPhoneAuthCode4ChangePhone({ phone: this.modifyAccount.account, areaCode: this.mobile_prefix })
				} else {
					this.$message.error(this.$t('WDNPC_WEB_PLEASE_ENTER_THE_CORRECT_MOBILE_PHONE_NUMBER', { text: '请输入正确的手机号' }))
				}
			}
		},
		// 更换邮箱 发送验证码
		async postAccountGetAuthCode3(type) {
			if (type === 'oldmail') {
				if (this.Countdown3 > 0) return
				this.Countdown3 = 60
				this.setCountdown3()
				this.$sensors.track('UM_Click_Verification_Code_Button')
				this.$api.account_new.getMailAuthCodeBySelf()
			} else {
				if (isEmail.test(this.modifyAccount.account)) {
					if (this.Countdown3 > 0) return
					this.Countdown3 = 60
					this.setCountdown3()
					this.$sensors.track('UM_Click_Verification_Code_Button')
					this.$api.account_new.getMailAuthCode4ChangeMail({ mail: this.modifyAccount.account })
				} else {
					this.$message.error(this.$t('WDNPC_WEB_ENTER_THE_CORRECT_EMAIL_ACCOUNT', { text: '请输入正确的邮箱账号' }))
				}
			}
		},
		// 绑定手机 发送验证码
		async postAccountGetAuthCode4() {
			this.bindAccount.authCode = null
			if (isPhone.test(this.bindAccount.account)) {
				if (this.Countdown4 > 0) return
				this.Countdown4 = 60
				this.setCountdown4()
				this.$sensors.track('UM_Click_Verification_Code_Button')
				this.$api.account_new.getPhoneAuthCode4ChangePhone({ phone: this.bindAccount.account, areaCode: this.mobile_prefix })
			} else {
				this.$message.error(this.$t('WDNPC_WEB_PLEASE_ENTER_THE_CORRECT_MOBILE_PHONE_NUMBER', { text: '请输入正确的手机号' }))
			}
		},
		setCountdown1() {
			this.CountdownInterval1 = setInterval(() => {
				this.Countdown1--
				if (this.Countdown1 <= 0) {
					clearInterval(this.CountdownInterval1)
				}
			}, 1000)
		},
		setCountdown2() {
			this.CountdownInterval2 = setInterval(() => {
				this.Countdown2--
				if (this.Countdown2 <= 0) {
					clearInterval(this.CountdownInterval2)
				}
			}, 1000)
		},
		setCountdown3() {
			this.CountdownInterval3 = setInterval(() => {
				this.Countdown3--
				if (this.Countdown3 <= 0) {
					clearInterval(this.CountdownInterval3)
				}
			}, 1000)
		},
		setCountdown4() {
			this.CountdownInterval4 = setInterval(() => {
				this.Countdown4--
				if (this.Countdown4 <= 0) {
					clearInterval(this.CountdownInterval4)
				}
			}, 1000)
		},
		backHome() {
			this.$router.push('/')
		},
	},
}
</script>

<style lang="stylus" scoped>
@import './css/accountSecurity.styl'
@import './css/accountSecurity_en.styl'
</style>
