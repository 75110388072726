<template>
	<div class="userInfo" :class="{ en: internationalKey === 'en-US' }">
		<div class="top-title">
			<span>{{ $t('WDNPC_WEB_MY_INFORMATION', { text: '我的资料' }) }}</span>
			<div class="longLine">
				<div class="shortLine"></div>
			</div>
		</div>
		<div class="content">
			<div class="personalInfoForm">
				<el-form class="form" ref="form" :model="form">
					<el-form-item :label="$t('WDNPC_WEB_USER_NAME', { text: '用户姓名：' })">
						<el-input
							v-model="form.name"
							:placeholder="$t('WDNPC_WEB_ENTER_A_USER_NAME', { text: '请输入用户姓名' })"
							:disabled="form.idAuthStatus == 1"
						></el-input>
					</el-form-item>
					<el-form-item :label="$t('WDNPC_WEB_NICKNAME', { text: '昵称：' })">
						<el-input
							v-model="form.nickname"
							:placeholder="$t('WDNPC_WEB_PLEASE_ENTER_A_NICKNAME', { text: '请输入昵称' })"
							:maxlength="15"
						></el-input>
					</el-form-item>
					<el-form-item class="genderRadio" :label="$t('WDNPC_WEB_GENDER', { text: '性别：' })">
						<el-radio-group class="radioGroup" v-model="form.gender">
							<el-radio :label="1">{{ $t('WDNPC_WEB_MALE', { text: '男' }) }}</el-radio>
							<el-radio :label="2">{{ $t('WDNPC_WEB_FEMALE', { text: '女' }) }}</el-radio>
							<el-radio :label="3">{{ $t('WDNPC_WEB_CONFIDENTIALITY', { text: '保密' }) }}</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item class="date" :label="$t('WDNPC_WEB_BIRTHDAY', { text: '生日：' })">
						<el-date-picker
							v-model="form.birthday"
							:picker-options="pickerOptions"
							type="date"
							value-format="yyyy-MM-dd HH:mm:ss"
							:placeholder="$t('WDNPC_WEB_SELECT_DATE', { text: '选择日期' })"
						>
						</el-date-picker>
					</el-form-item>
					<el-form-item class="region" :label="$t('WDNPC_WEB_CITY', { text: '所在城市：' })">
						
						<el-row  v-if="is_i18n" type="flex" justify="space-between">
							<el-col class="selectItem">
								<el-form-item prop="province" style="margin-bottom:0">
									<el-select v-model="formData.province" size="small" placeholder="" @change="handleProvinceChange">
										<el-option v-for="item in provinceList" :key="item.code" :label="item.name" :value="item.code"> </el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col class="selectItem">
								<el-form-item prop="city" style="margin-bottom:0">
									<el-select v-model="formData.city" size="small" placeholder="">
										<el-option v-for="item in cityList" :key="item.code" :label="item.name" :value="item.code"> </el-option>
									</el-select>
								</el-form-item>
							</el-col>
						</el-row>
                        <el-select
                            v-else
							v-model="form.regionCode"
							filterable
							remote
							reserve-keyword
							:placeholder="$t('WDNPC_WEB_ENTER_A_KEYWORD', { text: '请输入关键词' })"
							:remote-method="remoteMethod"
						>
							<el-option v-for="item in filterOptions" :key="item.code" :label="item.name" :value="item.code"> </el-option>
						</el-select>
					</el-form-item>
                    <!-- 国际化不展示实名认证 -->
					<template v-if="!is_i18n">
						<el-form-item :label="$t('WDNPC_WEB_ID_NUMBER', { text: '身份证号：' })" v-if="form.idCardNo">
							<el-input
								v-model="form.idCardNo"
								disabled
								:placeholder="$t('WDNPC_WEB_ENTER_YOUR_ID_NUMBER', { text: '请输入身份证号' })"
							></el-input>
						</el-form-item>
						<el-form-item :label="$t('WDNPC_WEB_ID_NUMBER', { text: '身份证号：' })" v-else>
							<el-button class="identifyBtn" @click="toIdentify">{{
								$t('WDNPC_WEB_GO_TO_IDENTITY_AUTHENTICATION', { text: '前往身份认证' })
							}}</el-button>
						</el-form-item>
					</template>
					<el-form-item>
						<el-button class="saveBtn" type="primary" @click="onSubmit">{{ $t('WDNPC_WEB_SAVE', { text: '保存' }) }}</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'userInfo',
	data() {
		return {
			pickerOptions: {
				disabledDate(time) {
					return time.getTime() > Date.now()
				},
			},
			filterOptions: [],
			form: {
				name: '',
				nickname: '',
				gender: '',
				birthday: '',
				regionCode: '',
				idCardNo: '',
			},
			formData: {
				province: '',
				city: '',
			},
			provinceList: [],
			areaList: [],
			userIdentityList: [],
			is_i18n: false,
			internationalKey: 'zh-CN',
		}
	},
	computed: {
		subUserInfo() {
			return this.$store.state.subUserInfo
		},
		cityList() {
			if (this.formData.province) {
				let findItem = this.provinceList.find((item) => item.code === this.formData.province)
				return findItem && findItem.children
			} else {
				return []
			}
		},
		districtList() {
			if (this.formData.city) {
				let findItem = this.cityList.find((item) => item.code === this.formData.city)
				return findItem && findItem.children
			} else {
				return []
			}
		},
	},
	async mounted() {
		this.internationalKey = localStorage.getItem('current_language') || 'zh-CN'
		this.is_i18n = localStorage.getItem('is_i18n') === 'true'
		this.form = { ...this.subUserInfo }
		if (this.is_i18n) {
            this.getRegioni18nTree()
			if (this.form.regionCode) {
				this.formData.city = this.form.regionCode
				this.formData.province = this.form.regionCode.substr(0, 2)
			}
		} else {
            await this.getTree()
            this.remoteMethod('', this.form.regionCode)
		}
	},
	methods: {
		handleProvinceChange() {
			this.formData.city = ''
			this.formData.district = ''
		},
		async onSubmit() {
			if (this.is_i18n) {
				this.form.regionCode = this.formData.city
            }
			this.$api.user_new.updateBySelf(this.form).then((res) => {
				if (res.success) {
					this.$message.success(this.$t('WDNPC_WEB_MODIFIED_SUCCESSFULLY', { text: '修改成功' }))
					this.getUserInfo()
				}
			})
		},
		toIdentify() {
			this.$parent.$emit('changeTab', '身份认证')
		},
		// 获取用户信息
		getUserInfo() {
			this.$api.user_new.getUserInfo().then((res) => {
				if (res.success) {
					this.$store.commit('saveSubUserInfo', res.data)
					localStorage.setItem('subUserInfo', JSON.stringify(res.data))
				}
			})
		},
		remoteMethod(query, code) {
			if (query !== '') {
				setTimeout(() => {
					this.filterOptions = this.areaList.filter((item) => {
						return item.name.includes(query)
					})
				}, 200)
			} else {
				this.filterOptions = this.areaList.filter((item) => {
					return item.code == code
				})
			}
		},
		//获取城市列表
		getTree() {
			if (this.$store.state.areaList.length > 0) {
				this.areaList = this.$store.state.areaList
			} else {
				return this.$api.region_new.getTree().then((res) => {
					res.data.forEach((item) => {
						const L1Name = item.name
						item.children &&
							item.children.length &&
							item.children.forEach((el) => {
								const L2Name = el.name
								if (!el.children) {
									this.areaList.push({
										name: `${L1Name}-${L2Name}`,
										code: el.code,
										id: el.id,
									})
								} else if (el.children.length) {
									el.children.forEach((v) => {
										const L3Name = v.name
										this.areaList.push({
											name: `${L1Name}-${L2Name}-${L3Name}`,
											code: v.code,
											id: v.id,
										})
									})
								}
							})
					})
					this.$store.commit('saveCityTree', this.areaList)
				})
			}
		},
		getRegioni18nTree() {
			return this.$api.region_new.getRegioni18nTree().then((res) => {
				this.provinceList = Object.freeze(res.data)
			})
		},
	},
}
</script>

<style lang="stylus" scoped src="./css/userInfo.styl"></style>
<style lang="stylus" scoped src="./css/userInfo_en.styl"></style>
