<template>
	<div class="personal" :class="{ en: internationalKey === 'en-US' }">
		<div class="personal-title">
			<p>{{ $t('WDNPC_WEB_PERSONAL_CENTER', { text: '个人中心' }) }}</p>
		</div>
		<div class="personal-content">
			<div class="avatar">
				<div class="avatarIcon" @mouseenter="handleMouseenter" @mouseleave="handleMouseleave">
					<img v-if="hasSettedAvatarUrl" :src="hasSettedAvatarUrl" alt="" />
					<img v-else src="../../../assets/images/common/userL.png" alt="" />
					<div class="mask" v-show="showMask" @click="uploadAvatarDialog = true">
						<span>
							{{ hasSettedAvatarUrl ? $t('WDNPC_WEB_CHANGE_AVATAR', { text: '更换头像' }) : $t('WDNPC_WEB_UPLOAD_AVATAR', { text: '上传头像' }) }}
						</span>
					</div>
				</div>
				<div class="name">
					<span class="span1">{{ name }}</span>
                    <!-- 国际化不展示 -->
					<span class="span2" v-if="!is_i18n" :class="{ isRealName }" @click="changeTab('身份认证')">{{
						isRealName ? $t('WDNPC_WEB_CERTIFIED', { text: '已认证' }) : $t('WDNPC_WEB_UNAUTHENTICATED', { text: '未认证' })
					}}</span>
				</div>
				<div class="learningTime">
					<svg-icon icon-class="learning_time"></svg-icon>
					<div class="record">
						<div class="left">
							<div class="left_top">
								<span>{{ learningTime.todayLearnTime }}</span>
								<span>h</span>
							</div>
							<div class="left_down">
								<span>{{ $t('WDNPC_WEB_LEARN_TODAY', { text: '今天学习' }) }}</span>
							</div>
						</div>
						<div class="right">
							<div class="left_top">
								<span>{{ learningTime.totalLearnTime }}</span>
								<span>h</span>
							</div>
							<div class="left_down">
								<span>{{ $t('WDNPC_WEB_CUMULATIVE_LEARNING', { text: '累计学习' }) }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<el-tabs
				:tab-position="'left'"
				v-model="activetab"
				style="height: 200px; width:1200px;"
				class="outerTabs"
				@tab-click="handleChangetab"
			>
				<el-tab-pane
					:label="$t('WDNPC_WEB_MY_STUDY', { text: '我的学习' })"
					:name="'我的学习'"
					v-if="configArr.is_show_my_study"
				>
					<span slot="label">
						<svg-icon icon-class="center-study"></svg-icon>
						{{ $t('WDNPC_WEB_MY_STUDY', { text: '我的学习' }) }}
					</span>
					<learning v-if="activetab === '我的学习'"></learning>
				</el-tab-pane>
				<el-tab-pane
					:label="$t('WDNPC_WEB_MY_CERTIFICATE', { text: '我的证书' })"
					:name="'我的证书'"
					v-if="configArr.is_show_my_certificate"
				>
					<span slot="label">
						<svg-icon icon-class="center-certificate"></svg-icon>
						{{ $t('WDNPC_WEB_MY_CERTIFICATE', { text: '我的证书' }) }}
					</span>
					<my-certificate @changeTab="changeTab" v-if="activetab === '我的证书'"></my-certificate>
				</el-tab-pane>
				<el-tab-pane
					:label="$t('WDNPC_WEB_MY_ORDER', { text: '我的订单' })"
					:name="'我的订单'"
					v-if="configArr.is_show_my_order && !is_i18n"
				>
					<span slot="label">
						<svg-icon icon-class="center-order"></svg-icon>
						{{ $t('WDNPC_WEB_MY_ORDER', { text: '我的订单' }) }}
					</span>
					<my-order v-if="activetab === '我的订单'"></my-order>
				</el-tab-pane>

				<el-tab-pane
					:label="$t('WDNPC_WEB_MY_EVALUATION', { text: '我的测评' })"
					:name="'我的测评'"
					v-if="configArr.is_show_my_evaluation && !is_i18n"
				>
					<span slot="label">
						<svg-icon icon-class="center-eval"></svg-icon>
						{{ $t('WDNPC_WEB_MY_EVALUATION', { text: '我的测评' }) }}
					</span>
					<evaluation v-if="activetab === '我的测评'"></evaluation>
				</el-tab-pane>
				<el-tab-pane
					:label="$t('WDNPC_WEB_MY_INFORMATION', { text: '我的资料' })"
					:name="'我的资料'"
					v-if="configArr.is_show_my_profile"
				>
					<span slot="label">
						<svg-icon icon-class="center-ziliao"></svg-icon>
						{{ $t('WDNPC_WEB_MY_INFORMATION', { text: '我的资料' }) }}
					</span>
					<userInfo @changeTab="changeTab" v-if="activetab === '我的资料'"></userInfo>
				</el-tab-pane>
				<el-tab-pane
					:label="$t('WDNPC_WEB_IDENTITY_AUTHENTICATION', { text: '身份认证' })"
					:name="'身份认证'"
                    v-if="!is_i18n"
				>
					<span slot="label">
						<svg-icon icon-class="center-idcard"></svg-icon>
						{{ $t('WDNPC_WEB_IDENTITY_AUTHENTICATION', { text: '身份认证' }) }}
					</span>
					<identify v-if="activetab === '身份认证'"></identify>
				</el-tab-pane>
				<el-tab-pane  :label="$t('WDNPC_WEB_ACCOUNT_SECURITY', { text: '账号安全' })" :name="'账号安全'">
					<span slot="label">
						<svg-icon icon-class="center-safe"></svg-icon>
						{{ $t('WDNPC_WEB_ACCOUNT_SECURITY', { text: '账号安全' }) }}
					</span>
					<accountSecurity v-if="activetab === '账号安全'"></accountSecurity>
				</el-tab-pane>
				<el-tab-pane :label="$t('WDNPC_WEB_MY_MESSAGE', { text: '我的消息' })" :name="'我的消息'">
					<span slot="label">
						<svg-icon icon-class="center-msg"></svg-icon>
						{{ $t('WDNPC_WEB_MY_MESSAGE', { text: '我的消息' }) }}
						<span class="unread-circle" v-if="existUnreadStatus"></span>
					</span>
                    <my-message
						v-if="activetab === '我的消息'"
						@updateMessageStatus="updateMessageStatus"
						@existUnread="existUnread"
					></my-message>
				</el-tab-pane>
			</el-tabs>
		</div>

		<!-- 头像上传 -->
		<el-dialog
			:title="$t('WDNPC_WEB_UPLOAD_AVATAR', { text: '上传头像' })"
			class="uploadAvatarDialog"
			:visible.sync="uploadAvatarDialog"
			:close-on-click-modal="false"
			width="480px"
			:before-close="cancelUploadAvatar"
		>
			<div class="dialogBody" v-loading="uploadAvatarLoading" :element-loading-text="$t('WDNPC_WEB_UPLOADING', { text: '正在上传' })">
				<el-upload
					class="avatar-uploader"
					ref="avatarUploader"
					accept="image/*"
					:action="action"
					:headers="headers"
					:show-file-list="false"
					:before-upload="handleAvatarBefore"
					:on-success="handleAvatarSuccess"
					:on-error="handleAvatarError"
				>
					<img v-if="avatarUrl || hasSettedAvatarUrl" :src="avatarUrl || hasSettedAvatarUrl" class="avatar" />
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
				<div v-if="avatarUrl || hasSettedAvatarUrl" class="preView">
					<div class="imgWrapper">
						<img :src="avatarUrl || hasSettedAvatarUrl" alt="" />
					</div>
					<div class="txt">{{ $t('WDNPC_WEB_AVATAR_PREVIEW', { text: '头像预览' }) }}</div>
				</div>
			</div>
			<span slot="footer" class="dialogFooter">
				<el-button @click="cancelUploadAvatar">{{ $t('WDNPC_WEB_CANCEL', { text: '取 消' }) }}</el-button>
				<el-button type="primary" @click="confirmUploadAvatar">{{ $t('WDNPC_WEB_OK', { text: '确 定' }) }}</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
/* eslint-disable */
import MD5 from 'js-md5'
import cercertificateIsShowStatus from './../../../utils/certificateMixin'
import config from '@/config/url'
import userInfo from '../userInfo/userInfo'
import identify from './identify'
import evaluation from '../../personal/page/evaluation'
import learning from '../../personal/page/myLearning' // 我的学习
import accountSecurity from '../accountSecurity/accountSecurity' //账号安全
import MyMessage from '../../personal/page/my-message' //我的消息
import MyCertificate from '../../personal/page/my-certificate' //我的证书
import MyOrder from '../../personal/page/my-order' //我的订单

export default {
	name: 'personalCenter',
	data() {
		return {
			learningTime: {
				todayLearnTime: '',
				totalLearnTime: '',
			}, //学习时长统计
			showMask: false, //更换头像
			existUnreadStatus: false, //是否有未读消息
			bEvaluate: false,
			themeContent: '',
			configArr: {
				is_show_my_profile: true,
				is_show_my_certificate: true,
				is_show_my_task: true,
				is_show_my_study: true,
				is_show_my_order: true,
				is_show_my_evaluation: true,
			},
			uploadAvatarLoading: false,
			action: config.API + '/resource/v1/resource/uploadImage',
			headers: {
				token: localStorage.getItem('token'),
			},
			avatarUrl: '',
			uploadAvatarDialog: false,
			// activetab: 'WDNPC_WEB_MY_INFORMATION', //{text:'我的资料'})',
			activetab: '我的资料', //{text:'我的资料'})',
			internationalKey: 'zh-CN',
            is_i18n:false
		}
	},
	mixins: [cercertificateIsShowStatus],
	created() {
        this.internationalKey = localStorage.getItem('current_language') || 'zh-CN'
        this.is_i18n = localStorage.getItem('is_i18n') === 'true'
		this.initConfig()
	},
	components: {
		userInfo,
		identify,
		evaluation,
		learning,
		accountSecurity,
		MyMessage,
		MyCertificate,
		MyOrder,
	},
	computed: {
		name() {
			return this.$store.state.subUserInfo.name
		},
		hasSettedAvatarUrl() {
			return this.$store.state.subUserInfo.headImage
        },
        isRealName(){
            return this.$store.state.subUserInfo.idAuthStatus
        }
	},
	mounted() {
		this.activetab = '我的资料'
		//根据保存的tab返回相应的tab
		if (sessionStorage.getItem('userCenterCurrentTab')) {
			this.activetab = sessionStorage.getItem('userCenterCurrentTab')
		}
		// csm配置跳转链接，跳转相应tab
		if (this.$route.query.userCenterCurrentTab) {
			this.activetab = this.$route.query.userCenterCurrentTab
		}
		// // 是否实名认证
		// this.postIsRealNameAuthentication()
		// 学习时长统计
		this.getAllLearnedData()
	},
	methods: {
		// postIsRealNameAuthentication() {
		// 	this.$api.monitor.postIsRealNameAuthentication({}).then((res) => {
		// 		const m = `${localStorage.getItem('token')}true`
		// 		this.isRealName = res.data === MD5(m)
		// 	})
		// },
		getAllLearnedData() {
			this.$api.learning.getAllLearnedData().then((res) => {
				this.learningTime = res.data
				console.debug(this.AllLearnedData)
			})
		},
		handleMouseenter() {
			this.showMask = true
		},
		handleMouseleave() {
			this.showMask = false
		},
		// 子组件修改tab
		changeTab(val) {
			this.activetab = val
		},
		// 更改未读消息状态
		updateMessageStatus(data) {
			this.existUnreadStatus = data
		},
		// 获取是否有未读消息
		existUnread() {
			this.$api.message.existUnread().then((res) => {
				if (res.data) {
					this.existUnreadStatus = true
				} else {
					this.existUnreadStatus = false
				}
				this.$store.commit('unreadMessageState', this.existUnreadStatus)
			})
		},
		initConfig() {
			/*
                is_show_my_certificate:false,
                is_show_my_task:false,
                is_show_my_study:false,
                is_show_my_order:false,
                is_show_my_evaluation:false
            */
			let configArr = localStorage.getItem('configurationArr') && JSON.parse(localStorage.getItem('configurationArr'))
			configArr.forEach((item) => {
				switch (item.key) {
					case 'is_show_my_profile':
						this.configArr[item.key] = item.value === 'false' ? false : true
						break
					case 'is_show_my_certificate':
						this.configArr[item.key] = item.value === 'false' ? false : true
						break
					case 'is_show_my_task':
						this.configArr[item.key] = item.value === 'false' ? false : true
						break
					case 'is_show_my_study':
						this.configArr[item.key] = item.value === 'false' ? false : true
						break
					case 'is_show_my_order':
						this.configArr[item.key] = item.value === 'false' ? false : true
						break
					case 'is_show_my_evaluation':
						this.configArr[item.key] = item.value === 'false' ? false : true
						break
					default:
						break
				}
			})
		},
		// 确认修改头像
		confirmUploadAvatar() {
			if (!this.avatarUrl) {
				this.$message.error(this.$t('WDNPC_WEB_PLEASE_UPLOAD_THE_AVATAR', { text: '请上传头像' }))
				return
			}
			this.$api.user_new.updateBySelf({ headImage: this.avatarUrl, ucode: this.$store.state.subUserInfo.ucode }).then((res) => {
				if (res.success) {
					this.$message.success(this.$t('WDNPC_WEB_MODIFIED_SUCCESSFULLY', { text: '修改成功' }))
					this.uploadAvatarDialog = false
					this.getUserInfo()
				}
			})
		},
		cancelUploadAvatar() {
			this.$refs.avatarUploader.abort()
			this.uploadAvatarLoading = false
			this.avatarUrl = ''
			this.uploadAvatarDialog = false
		},
		handleAvatarBefore(file) {
			const isImg = file.type.includes('image')
			if (!isImg) {
				this.$message.error(this.$t('WDNPC_WEB_ONLY_IMAGES_CAN_BE_UPLOADED', { text: '只能上传图片' }) + '!')
				return false
			}
			this.uploadAvatarLoading = true
		},
		// 头像上传成功
		handleAvatarSuccess(res, file) {
			this.avatarUrl = file.response.data
			this.uploadAvatarLoading = false
		},
		handleAvatarError(err) {
			this.uploadAvatarLoading = false
			this.$message.error(this.$t('WDNPC_WEB_AVATAR_UPLOAD_FAILED', { text: '头像上传失败' }))
		},
		// 获取用户信息
		getUserInfo() {
			this.$api.user_new.getUserInfo().then((res) => {
				if (res.success) {
					this.$store.commit('saveSubUserInfo', res.data)
					localStorage.setItem('subUserInfo', JSON.stringify(res.data))
				}
			})
		},
		findAndSetPayStatus(data) {
			this.Countdown = null
			const payNos = data || this.qrcodeState.payNo
			this.Countdown = setInterval(() => {
				this.$api.home.findAndSetPayStatus(payNos).then((res) => {
					if (res.data == true) {
						clearInterval(this.Countdown)
						this.type = 'success'
						sessionStorage.setItem('typeState', this.type)
						sessionStorage.removeItem('qrcodeState')
					} else if (res.code != 0) {
						clearInterval(this.Countdown)
						this.type = 'fail'
						sessionStorage.setItem('typeState', this.type)
						sessionStorage.removeItem('qrcodeState')
					}
				})
			}, 3000)
		},
		// 关闭弹框,清除已经生成的二维码
		closeCode() {
			this.paymentdialogVisible = false
			sessionStorage.setItem('dialogState', this.paymentdialogVisible)
			this.$refs.qrCodeDiv.innerHTML = ''
			clearInterval(this.Countdown)
			sessionStorage.removeItem('dialogState')
			sessionStorage.removeItem('typeState')
			sessionStorage.removeItem('qrcodeState')
		},
		// 生成二维码
		qrcodeimg(data) {
			this.paymentdialogVisible = true
			// 缓存弹框状态
			sessionStorage.setItem('dialogState', this.paymentdialogVisible)
			this.type = 'payment'
			// 缓存支付状态
			sessionStorage.setItem('typeState', this.type)
			setTimeout(() => {
				new QRCode(
					this.$refs.qrCodeDiv,
					{
						text: data.codeUrl,
						width: 150,
						height: 150,
						colorDark: '#333333', // 二维码颜色
						colorLight: '#ffffff', // 二维码背景色
						correctLevel: QRCode.CorrectLevel.H, // 容错率L/M/H
					},
					100
				)
			})
			this.findAndSetPayStatus(data.payNo)
		},
		applyInvoiceShow() {
			this.applyInvoice = false
		},
		handleChangetab(tab) {
			this.activetab = tab.name;
			sessionStorage.removeItem('userCenterCurrentTab') //移除当前保存的tab
		},
		myOrderList() {
			this.$api.certificate.myOrderList().then(({ data }) => {
				this.orderlist = data
				this.hasOrder = this.orderlist.length > 0
				this.countDown()
			})
		},
    },
    beforeDestroy(){
        sessionStorage.removeItem('userCenterCurrentTab') //移除当前保存的tab
    }
}
/* eslint-disable no-new */
</script>
<style lang="stylus" scoped src="./css/personalCenter.styl"></style>
<style lang="stylus" scoped src="./css/personalCenter_en.styl"></style>
