<template>
    <div class="identify">
        <div class="noIdentify" v-if="!isRealName">
            <div class="QRbox">
                <div id="identifyqrcode" style="width:180px;height:180px;"></div>
            </div>
            <div class="tip">扫描上方二维码进入手机端认证页面</div>
        </div>
        <div class="hasIdentified" v-else>
            <div class="icon">
                <img src="../../../assets/images/common/success.png" alt="">
            </div>
            <p>已认证</p>
            <p>{{name}}（{{idCard}}）</p>
            <p>证件照片：已上传</p>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import {mapState} from 'vuex'
import MD5 from 'js-md5'
import QRCode  from "qrcodejs2"
import conf  from '@/config/url'
export default {
    name:'identify',
    data:() => {
        return{
            theme:'',
        }
    },
    computed:{
        ...mapState({
            phoneNumber:state => state.userInfo.phone,
            name:state => state.subUserInfo.name && ('*'+ state.subUserInfo.name.charAt(state.subUserInfo.name.length-1)),
            idCard:state => state.subUserInfo.idCardNo,
            isRealName:state => state.subUserInfo.idAuthStatus
        })
    },
    watch:{
        isRealName:{
            handler:function(val){
                if(val == 0){
                    this.$nextTick(() => {
                        this.qrcode()
                    })
                }
            },
            immediate: true
        }
    },
    mounted(){
        this.theme = localStorage.getItem('theme')
        // this.postIsRealNameAuthentication()
    },
    methods:{
        // postIsRealNameAuthentication () {
        //     this.$api.monitor.postIsRealNameAuthentication({}).then(res => {
        //         const m = `${localStorage.getItem('token')}true`
        //         this.isRealName = res.data === MD5(m)
        //         // console.log('identify',this.isRealName)
                
        //         this.hasConfirmed = true//避免闪屏
        //         this.$nextTick(() => {
        //             !this.isRealName && this.qrcode()
        //         })
        //     })
        // },
        async qrcode () {
            let domainArr = localStorage.getItem('domain')
            let domainUrl = domainArr && JSON.parse(domainArr).find(item => item.frontend_template_id == 4).domain
            if(this.QRCode) return
            this.QRCode = new QRCode('identifyqrcode', {
                width: 180,
                height: 180,        // 高度
                // text:  `https://${domainUrl || 'hrss-stu.testing2.wdeduc.com'}/login?phone=${this.phoneNumber}`,   // 二维码内容
                text:  `${window.location.protocol}//${domainUrl}/login?phone=${this.phoneNumber}`,   // 二维码内容
                display: 'none'
                // render: 'canvas' ,   // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
                // background: '#f0f',   // 背景色
                // foreground: '#ff0'    // 前景色
            })
        },
    }
}
/* eslint-disable no-new */
</script>

<style lang="stylus" scoped>
    .noIdentify
        .QRbox
            width 240px
            height 240px
            margin 0 auto
            margin-top 196px
            border 1px solid #F0F0F0
            border-radius 4px
            background #ffffff
            display flex
            justify-content center
            align-items center
        .tip
            margin-top 16px
            text-align center
            font-size 20px
            color #333
            line-height 28px
    .hasIdentified
        .icon
            margin 0 auto
            margin-top 196px
            width 150px
            height 150px
            >img 
                display block
                width 100%
                height 100%
        >p  
            margin-top 4px
            font-size 16px
            color #333333
            text-align center
            line-height 22px
        >p:first-of-type
            margin-top 8px
            margin-bottom 16px
            font-size 24px
            color #20D14A
            line-height 33px
        


</style>