import { render, staticRenderFns } from "./personalCenter.vue?vue&type=template&id=0fdda343&scoped=true"
import script from "./personalCenter.vue?vue&type=script&lang=js"
export * from "./personalCenter.vue?vue&type=script&lang=js"
import style0 from "./css/personalCenter.styl?vue&type=style&index=0&id=0fdda343&prod&lang=stylus&scoped=true&external"
import style1 from "./css/personalCenter_en.styl?vue&type=style&index=1&id=0fdda343&prod&lang=stylus&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fdda343",
  null
  
)

export default component.exports